


:root{

    --amplify-primary-color: #38b6ff;
    --amplify-primary-tint: #4796c4; 
    --amplify-primary-shade: #38b6ff;
    
    }

//     $secondary-color: #38b6ff;
// $third-color: #c98d26;


.modal-90w {
    max-width: 90%;
    min-height: 90%;

    .modal-content {
        height: 100%
    }
}


.fullscreen-bg {
    background: $navbar-bg;
}