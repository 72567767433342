


.write-editor {
    .title{
        max-width: 650px;
        margin : 0 auto;
        input {
            font-size: 2.3125rem !important;
            box-shadow: none;
            border: none;
        }
        .form-control:disabled{
            background-color: #ffffff;
        }
    }


    .thumbnail-container {
        position: relative;
        height: 150px;
        .thumb {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .ce-code__textarea {
        min-height: 40px;
        height: 40px;
    }
    
}

.editor-social i {
    font-size: 1.8rem ;
}

@media (min-width: 651px) {
    .codex-editor--narrow .codex-editor__redactor {
        margin-right: 0px;
    }
}



.editor-profile {
    .picture-container {
        border-radius: 50%;
        width: 160px;
        height: 160px;
        margin: 0 auto;
        i {
            font-size: 4.5rem;
        }
    }
}