@each $color, $value in $theme-colors {
  .bg-inverse-icon-#{$color} {
    @include bg-inverse-icon($value);
  }
}


.bg-white-smoke {
  background-color: $white-smoke;
}
.bg-gray-light {
  background-color: $white-smoke;
}
.bg-dark-primary {
  background-color: $primary-dark;
}

.dashed-border {
  border: 1px dashed;
  &.nooutline:focus {
    outline: none;
    border-color: $secondary-color;
  }
}
