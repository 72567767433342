



.article-container {
    cursor: pointer;
    .article-hr {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
    }
    .article-title {
        height: 100px;
    }
    .article-thumbnail {
        position: relative;
    }
    .article-tag {
        background: $third-color;
        width: 25%;
        color: white;
        border-radius: 2px;
        text-align: center;
        font-weight: bold;
        position: absolute;
        right: 5%;
        top: 5%;
        &.new-tag {
            background: $secondary-color;
        }
    }
}

.article-container:hover {
    .article-hr {
        border-top: 4px solid $navbar-highlight-color;
    }
    .article-thumbnail {
        .cover {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.2);
        }
    }
}


.serie-row {
    overflow-x: scroll;
    white-space: nowrap;
    display: block;
    .serie-article {
        display: inline-flex;
    }

    .carousel.slide {
        padding-left: 4%;
        padding-right: 4%;
    }

    .carousel-control-next {
        width: 5%;
    }
    .carousel-control-prev {
        width: 5%;
    }
}



.article-read-container {
    .article-tag {
        background: $third-color;
        color: white;
        border-radius: 2px;
        font-weight: bold;
    }

    .paragraph-content {
        font-size: 1rem;
        line-height: 1.75;
        text-align: justify;
        font-weight: 400;
        a {
            color: $secondary-color;
        }
        code {
                // padding: 5px;
                color: $body-color;
                // font-family: "Roboto", sans-serif;
                // font-weight: 300;
                // font-size: 0.875rem;
                // border-radius: 4px;
                font-size: 1rem;
                line-height: 1.75;
                font-weight: 400;
                display: block;
                background: $secondary-content-bg;
        }
    }

    .article-sidebar {
        a {
            color: $secondary-color;
        }
    }
}


.article-navlink {
    border: none;
    color: $body-color !important;
    &:focus{
        box-shadow: none !important;
        border: none;
        color: $body-color !important;
    }
}
.article-navlink-danger {
    background-color: $danger;
    p {
        color: white;
    }
    &:hover{
        background-color: darken($danger, 10%);
    }
    &:focus{
        background-color: darken($danger, 18%);
    }
    &:active{
        background-color: darken($danger, 18%) !important;
    }
}
.article-delete-modal {
    .modal-footer {
        border-top: none;
    }
    .modal-header {
        border-bottom: none;
    }

    .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
        position: absolute;
        top: 6px;
        left: 0;
    }

    .form-group {
        max-height: 450px;
        overflow-y: scroll;
    }
    .select-article-container {
        &:hover{
            background-color: #fbfbfb;
        }
    }
}


.bg-serie-article {
    background-color: $secondary-bg;
}

.new-article-grid {
    height: 306px;
    @media (max-width: 767px) {
        height: 580px;
    }
}

.card {
    &.no-shadow {
        box-shadow: none;
    }        
    &.border {
        border: 1px solid rgba(0,0,0,.125)
    }
}

.serie-article-slider {
    height: 314px;
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c98d26' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    }
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c98d26' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    }
}


.serie-explorer {
    .serie-container {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        .serie-item {
            cursor: pointer;
            &:hover {
                color: $secondary-color;
            }
            &.active {
                color: darken($secondary-color, 18%);
            }
            &.disabled {
                cursor: not-allowed;
                color: #adadad;
                &:hover {
                    color: #adadad;
                }
            }
        }
    }
}

@media (max-width:991px) {
    .serie-explorer {
        .serie-container {
            border-right: none;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}