


.circled-profile-picture {
    max-width: 100%;
    border-radius: 50%;
    background: $secondary-content-bg;
    height: 160px;
    width: 160px;
    margin: 0 auto;
}